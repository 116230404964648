<template>
  <div class="confirmation">
    <div class="modal__body">
      Are you sure that you want to delete this report permanently?
    </div>
    <div class="modal__footer">
      <button
        type="button"
        class="button button_text button_md"
        @click="$emit('close')"
      >
        cancel
      </button>
      <button
        type="button"
        class="button button_danger button_md"
        @click="submitHandler"
      >
        delete
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: { type: Object, required: true },
  },

  methods: {
    submitHandler() {
      this.options.cb();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.modal__body {
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: $color-primary;
  font-weight: $weight-bold;
  font-size: $size-sm * 1.2;
  text-align: center;
}
</style>
